<template>
  <div class="w-p-100 h-p-100 bg-f3">
    <div class="h-54 w-p-100 flex alignCenter justifyBetween">
      <van-search
        v-model="query.name"
        show-action
        background="#F3F3F3"
        @search="onSearch(1)"
        placeholder="关键字查询"
      >
        <template #action>
          <div class="flex alignCenter">
            <van-field
              class="searchInputBox"
              v-model="query.date_time"
              name="calendar"
              placeholder="点击选择日期"
              @click="showCalendar = true"
            />
            <van-icon
              name="search"
              class="size-25"
              color="#0F60A7"
              @click="onSearch(1)"
            />
          </div>
        </template>
      </van-search>
    </div>
    <div class="h-calc-54">
      <van-pull-refresh
        v-model="loading"
        @refresh="refresh"
        @load="onLoad"
        :finished="finished"
        finished-text="没有更多了"
        class="h-p-100 scroll-y"
      >
        <div v-if="list.length > 0" class="w-p-100 h-p-100">
          <div
            class="w-calc-48 Fbg lP-24 rP-24 bM-4 tP-5 bP-5 relative"
            v-for="item in list"
            :key="item"
          >
            <div class="flex alignCenter justifyBetween size-20">
              {{ item.types_1_name }}
            </div>
            <div
              class="h-35 flex alignCenter justifyBetween"
              v-if="item.teacher_name || item.classroom"
            >
              <div class="size-16 color-80">{{ item.teacher_name }}</div>
              <div class="size-16 color-80 w-150">{{ item.classroom }}</div>
            </div>
            <div
              class="flex alignCenter justifyBetween size-14 color-80 w-p-100"
            >
              {{ item.types_2_name }}
            </div>
            <div class="h-35 w-p-100 flex alignCenter size-18 color-80">
              {{ item.train_start_date }}
            </div>
            <div class="h-35 flex alignCenter justifyBetween size-18 color-80">
              <div class="flex r-24 b-12 alignCenter w-p-100 justifyEnd">
                <div
                  class="size-14 flex alignCenter justifyCenter  bg-0F60A7 color-fff  padding-5 lM-5 radius-4"
                  v-if="
                    item.role == 1 && item.is_signin == 0 && item.is_today == 1
                  "
                  @click="sign(item.ct_train_id)"
                >
                  签到
                </div>
                <div
                  class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff  padding-5 lM-5 radius-4"
                  v-if="
                    item.role == 1 &&
                      item.is_signin == 1 &&
                      item.has_test == 1 &&
                      !item.first_score &&
                      item.is_today == 1
                  "
                  @click="
                    router.push('/AnswerDetails/' + item.ct_train_id + '/0/0')
                  "
                >
                  答题
                </div>
                <div
                  class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff  padding-5 lM-5 radius-4"
                  v-if="
                    item.role == 1 &&
                      item.is_signin == 1 &&
                      item.has_test == 1 &&
                      item.first_score
                  "
                  @click="router.push('/TrainAnswerDetail/' + item.ct_train_id)"
                >
                  考试详情
                </div>
                <div
                  class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff  padding-5 lM-5 radius-4"
                  v-if="
                    item.role == 1 &&
                      item.is_signin == 1 &&
                      item.has_test == 1 &&
                      item.first_score &&
                      item.is_qualified == 0 &&
                      item.is_today == 1
                  "
                  @click="
                    router.push('/AnswerDetails/' + item.ct_train_id + '/0/0')
                  "
                >
                  订正
                </div>
                <div
                  class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff padding-5 lM-5 radius-4"
                  v-if="item.role == 3 && item.has_test != 1"
                  @click="router.push('/TrainPaper/' + item.ct_train_lesson_id)"
                >
                  生成试卷
                </div>
                <div
                  class="size-14 flex alignCenter justifyCenter bg-0F60A7 color-fff padding-5 lM-5 radius-4"
                  v-if="item.role == 3 && item.has_test == 1"
                  @click="
                    router.push('/TrainResult/' + item.ct_train_lesson_id)
                  "
                >
                  考试结果
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <van-empty description="暂无数据"></van-empty>
        </div>
      </van-pull-refresh>
    </div>
  </div>
  <van-calendar
    v-model:show="showCalendar"
    @confirm="calendarOnConfirm"
    :min-date="new Date('2000-01-01')"
  />
</template>
<script>
/* eslint-disable */
import { onMounted, reactive ,ref} from 'vue';
import http from '../../../api/http';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import {Toast} from 'vant'
export default {
    setup() {
        const router = useRouter();
        const showCalendar = ref(false);
        const list = ref([]);
        const query = reactive({
            date_time:dayjs(new Date()).format('YYYY-MM-DD'),
            keyword:'',
            method:'query',
            queryId:1058,
            page:1,
            pageSize:20
        });
        
        const finished = ref(false)
        const loading = ref(false);
        const lodingmore = ref(false)
        const onSearch = (page) =>{
            query.page = page;
            loading.value = false;
            Toast.loading({message: '加载中...',duration:0});
            http.get(query)
            .then(res=>{
                console.log(res)
                list.value = res.map;
                
            })
            .catch(()=>{
                Toast.clear();
            })
            // finished.value = true;
        }
        // 下拉刷新
        const refresh = () =>{
            loading.value = true;
            onSearch(1)
        }
        // 
        const onLoad = ()=>{

        }
        const calendarOnConfirm = (date) =>{
            query.date_time =  dayjs(date).format('YYYY-MM-DD');//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            showCalendar.value = false;
            onSearch(1)
        }

        // 签到
        const sign = (id) =>{
            http.post({
                method:'query',
                queryId:1059,
                ct_train_id:id
            })
            .then(()=>{
                onSearch(1)
            })
        }

        
        onMounted(()=>{
            onSearch(1);
        })
        return {
            query,
            onSearch,
            refresh,
            finished,
            loading,
            list,
            onLoad,
            lodingmore,
            showCalendar,
            sign,
            router,
            calendarOnConfirm,
            dayjs
        }
    },
}
</script>